<template>
  <rental-worksite type-vue="create" type-mission="reservation" />
</template>

<script>
import RentalWorksite from '@/components/driver/rentalCoupon/RentalWorksite';
export default {
  name: 'RentalWorksiteCreate',
  components: { RentalWorksite },
};
</script>
