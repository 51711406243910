<template>
  <ion-page>
    <WhiteHeader
      v-if="typeVue === 'create'"
      :back-button="true"
      :route-back="
        '/transfert/' + this.$route.params.id + '/' + this.$route.params.idRide + '/' + this.$route.params.idItem
      "
      title="Création d'un BL"
    />

    <WhiteHeader
      v-else
      :back-button="true"
      :route-back="
        '/transfert/' + this.$route.params.id + '/' + this.$route.params.idRide + '/' + this.$route.params.idItem
      "
      :title="oldRentalCoupon.name"
    />

    <ion-content class="ion-padding">
      <form @submit.prevent="sendRentalCoupon()">
        <ion-grid>
          <!-- v-if to avoid race condition -->
          <date
            v-if="start && end"
            v-model:start="start"
            v-model:end="end"
            v-model:start-default="startDefault"
            v-model:end-default="endDefault"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-on:updateDateDependent="updateInformationsDateDependent"
          />

          <transfer-details
            v-model:machines="machines"
            v-model:tools="tools"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            :disabled="did_signature_source || old_draw_signature_source"
          />

          <!-- v-show don't work directly on prestation so wrap in div -->
          <div v-show="isContactView">
            <prestation
              v-model:segment-activities="segmentActivities"
              v-model:segment-prestation="segmentPrestation"
              v-model:segment-bad-weather="segmentBadWeather"
              v-model:segment-maintenance="segmentMaintenance"
              v-model:times="times"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              v-model:readable-date="readableDate"
              v-model:start="start"
              v-model:end="end"
              v-model:initialized="initialized"
              v-model:pricing-information="missionItem.pricing_information"
              v-model:amount-flat-rates="missionItem.amount_flat_rates"
              v-model:selected-modifiers="selectedModifiers"
              :modifiers="missionItem.modifiers"
            />
          </div>

          <ion-row class="title-part-padding-form-driver">
            <ion-col size="9" class="row-align-center">
              <ion-label
                class="title-part-form-driver"
                @click="toggleCharge = !toggleCharge"
                >Chargement<ion-badge
                  v-if="
                    missionItem.coupon_company &&
                    missionItem.coupon_company === 'source'
                  "
                  color="success"
                  class="ml-10"
                  >Client</ion-badge
                ></ion-label
              >
            </ion-col>
            <ion-col class="justify-content-end">
              <ion-toggle
                v-model="toggleCharge"
                mode="md"
                color="dark"
              ></ion-toggle>
            </ion-col>
          </ion-row>

          <client-details
            v-model:toggle-show="toggleCharge"
            v-model:builder_name="source_builder_name"
            v-model:segment-contact="segmentSourceContact"
            v-model:contact_id="source_contact_id"
            v-model:supervisors-builder="sourceSupervisorsBuilder"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:contact_name="source_contact_name"
            v-model:contact_email="source_contact_email"
            v-model:builder_id="missionItem.source_id"
            v-model:worksite_name="source_worksite_name"
            v-model:old-contact-id="oldRentalCoupon.source_contact_id"
            v-model:old-contact-email="oldRentalCoupon.source_contact_email"
            v-model:type-vue="typeVue"
            v-model:status="status"
            :type-mission="typeMission"
            type-client="source"
            :coupon_company="missionItem.coupon_company"
          />

          <ion-row
            v-show="isContactView"
            class="title-part-padding-form-driver"
          >
            <ion-col size="9" class="row-align-center">
              <ion-label
                class="title-part-form-driver"
                @click="toggleDischarge = !toggleDischarge"
                >Déchargement<ion-badge
                  v-if="
                    missionItem.coupon_company &&
                    missionItem.coupon_company === 'target'
                  "
                  color="success"
                  class="ml-10"
                  >Client</ion-badge
                ></ion-label
              >
            </ion-col>
            <ion-col class="justify-content-end">
              <ion-toggle
                v-model="toggleDischarge"
                mode="md"
                color="dark"
              ></ion-toggle>
            </ion-col>
          </ion-row>

          <div v-show="isContactView">
            <client-details
              v-model:toggle-show="toggleDischarge"
              v-model:builder_name="target_builder_name"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              v-model:worksite_name="target_worksite_name"
              v-model:old-contact-id="oldRentalCoupon.target_contact_id"
              v-model:old-contact-email="oldRentalCoupon.target_contact_email"
              v-model:type-vue="typeVue"
              :type-mission="typeMission"
              type-client="target"
              :coupon_company="missionItem.coupon_company"
              :source_signed="
                old_draw_signature_source ||
                (typeVue !== 'create' && no_signature_source)
              "
            />
          </div>

          <internal-references
              v-model:worksite_reference="worksite_reference"
              v-model:order_number="order_number"
          ></internal-references>
        </ion-grid>

        <ion-grid>
          <div v-show="isContactView">
            <options
              ref="options"
              v-if="
                missionItem.coupon_company &&
                missionItem.coupon_company === 'target'
              "
              v-model:toggle-comment="toggleComment"
              v-model:comments="comments"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              :type-mission="typeMission"
              v-model:segment-contact="segmentTargetContact"
              v-model:contact_id="target_contact_id"
              v-model:supervisors-builder="targetSupervisorsBuilder"
              type-client="target"
              :coupon_company="missionItem.coupon_company"
              v-model:contact_name="target_contact_name"
              v-model:builder_id="missionItem.target_id"
              v-model:contact_email="target_contact_email"
              v-model:contact_phone="target_contact_phone"
              v-model:status="status"
              v-model:old-contact-id="oldRentalCoupon.target_contact_id"
              v-model:old-contact-email="oldRentalCoupon.target_contact_email"
              v-model:attachments="oldRentalCoupon.attachments"
              :files-too-big="filesTooBig"
            />
            <options
              ref="options"
              v-else-if="
                missionItem.coupon_company &&
                missionItem.coupon_company === 'source'
              "
              v-model:toggle-comment="toggleComment"
              v-model:comments="comments"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              :type-mission="typeMission"
              v-model:segment-contact="segmentSourceContact"
              v-model:contact_id="source_contact_id"
              v-model:supervisors-builder="sourceSupervisorsBuilder"
              type-client="source"
              :coupon_company="missionItem.coupon_company"
              v-model:contact_name="source_contact_name"
              v-model:builder_id="missionItem.source_id"
              v-model:contact_email="source_contact_email"
              v-model:contact_phone="source_contact_phone"
              v-model:status="status"
              v-model:old-contact-id="oldRentalCoupon.source_contact_id"
              v-model:old-contact-email="oldRentalCoupon.source_contact_email"
              v-model:attachments="oldRentalCoupon.attachments"
              :files-too-big="filesTooBig"
            />
          </div>

          <signature
            ref="signature"
            v-model:did_signature_driver="did_signature_driver"
            v-model:canvas_signature_driver="canvas_signature_driver"
            v-model:old_draw_signature_driver="old_draw_signature_driver"
            v-model:did_signature_source="did_signature_source"
            v-model:canvas_signature_source="canvas_signature_source"
            v-model:old_draw_signature_source="old_draw_signature_source"
            v-model:did_signature_contact="did_signature_contact"
            v-model:canvas_signature_contact="canvas_signature_contact"
            v-model:old_draw_signature_contact="old_draw_signature_contact"
            v-model:no_signature_contact="no_signature_contact"
            v-model:no_signature_source="no_signature_source"
            v-model:signature_contact="oldRentalCoupon.signature_contact"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:need_signature_source="missionItem.need_signature_source"
            v-model:is-contact-view="isContactView"
            v-on:clearSignature="clearSignature"
            is-transport="true"
          />

          <internal-information
            ref="internalInformation"
            v-model:notes="notes"
            :type-vue="typeVue"
            :status="status"
            :files-too-big="filesTooBig"
            v-model:attachments="oldRentalCoupon.attachments"
          />

          <!--
          <information-text
            :status="status"
            :is-source-sendable="isSourceSendable"
            :is-contact-view="isContactView"
            kind="transfer"
          ></information-text>
          -->

          <ion-col>
            <ion-button
              color="tertiary"
              class="mt-10"
              strong="true"
              expand="block"
              type="submit"
              :disabled="isCouponSendable || isSourceSendable"
              >{{
                oldRentalCoupon.status === 'send'
                  ? 'Enregistrer'
                  : 'Enregistrer en brouillon'
              }}</ion-button
            >

            <ion-button
              v-if="isContactView"
              color="success"
              class="mt-10"
              strong="true"
              expand="block"
              type="submit"
              :disabled="!isCouponSendable"
              >Envoyer au contact</ion-button
            >

            <ion-button
              v-else
              color="success"
              class="mt-10"
              strong="true"
              expand="block"
              type="submit"
              @click="sendPdfToSource = true"
              :disabled="!isSourceSendable"
              >Enregistrer et envoyer au chargement</ion-button
            >
          </ion-col>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonToggle,
  IonBadge,
} from '@ionic/vue';
import Date from '@/components/driver/rentalCoupon/components/Date';
import ClientDetails from '@/components/driver/rentalCoupon/components/ClientDetails';
import Prestation from '@/components/driver/rentalCoupon/components/Prestation';
import Signature from '@/components/driver/rentalCoupon/components/Signature';
import InternalInformation from '@/components/driver/rentalCoupon/components/InternalInformation';
// import InformationText from '@/components/driver/rentalCoupon/components/InformationText';
import InternalReferences from '@/components/driver/rentalCoupon/components/InternalReferences';
import Options from '@/components/driver/rentalCoupon/components/Options';
import { mixinDataAllTypeRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataAllTypeRentalCoupon';
import { mixinDataTransportRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataTransportRentalCoupon';
import {
  getDetailMission,
  getMissionOrder,
} from '@/services/driver/missionsOrders';
import { getInfo } from '@/services/users';
import { fetchRentalCoupon } from '@/services/driver/rentalCoupon';
import TransferDetails from '@/components/driver/rentalCoupon/components/TransferDetails';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import utils from '@/services/utils/Utils';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

export default {
  name: 'RentalTransfer',
  components: {
    WhiteHeader,
    TransferDetails,
    Options,
    InternalInformation,
    // InformationText,
    InternalReferences,
    Signature,
    Prestation,
    ClientDetails,
    Date,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonToggle,
    IonBadge,
  },
  mixins: [mixinDataAllTypeRentalCoupon, mixinDataTransportRentalCoupon],
  data() {
    return {
      machines: [],
      tools: [],
    };
  },
  async mounted() {
    // Get informations on the mission
    this.mission = await getMissionOrder(
      this.$route.params.id,
      this.$route.params.idRide,
      this.typeMission,
      this.$route.params.idRentalCoupon,
    );
    for (const transfer in this.mission['transport_items']) {
      // first case : we have an item, so it's the id of the item
      // second case : we don't have an item, so it's the id of the rental_coupon
      if ((transfer === this.$route.params.idItem) || (transfer === this.$route.params.idRentalCoupon)) {
        this.missionItem = this.mission['transport_items'][transfer];
      }
    }

    if (!this.missionItem.pricing_information) {
      utils.routeForbidden(this.$router, this.$route, 'transfert');
    } else {
      this.times.prestation.type = this.getPrestationType(this.missionItem);
    }

    this.missionDetail = await getDetailMission(
      this.typeMission,
      this.$route.params.id,
      this.$route.params.idItem,
      this.$route.params.idRentalCoupon,
    );

    const { userData } = await getInfo();
    this.user = userData;

    this.initializationSignatureData();

    this.startDefault = this.mission.start;
    this.endDefault = this.mission.end;
    this.source_builder_name = this.missionItem.source;
    this.target_builder_name = this.missionItem.target;

    this.toggleCharge = true;
    this.toggleDischarge = true;

    if (this.typeVue !== 'create') {
      this.oldRentalCoupon = await fetchRentalCoupon(
        this.$route.params.idRentalCoupon,
      );

      if (this.oldRentalCoupon.status === 'send') {
        this.oldRentalCouponSending = true;
      }

      this.start = this.oldRentalCoupon.start;
      this.end = this.oldRentalCoupon.end;

      if (this.oldRentalCoupon.start < this.startDefault) {
        this.startDefault = this.oldRentalCoupon.start;
      }
      if (this.oldRentalCoupon.end > this.endDefault) {
        this.endDefault = this.oldRentalCoupon.end;
      }
      this.pricing_fuel = this.oldRentalCoupon.pricing_fuel;
      this.pricing_driver = this.oldRentalCoupon.pricing_driver;
      this.comments = this.oldRentalCoupon.comments;

      this.old_draw_signature_contact = this.oldRentalCoupon.signature_contact;
      this.old_draw_signature_driver = this.oldRentalCoupon.signature_driver;
      this.old_draw_signature_source = this.oldRentalCoupon.signature_source;
      this.no_signature_contact = this.oldRentalCoupon.no_signature_contact;
      this.no_signature_source = this.oldRentalCoupon.no_signature_source;
      this.need_signature_source = this.missionItem.need_signature_source;

      this.setToggle(this.typeMission);
      this.setModifiersKeys(this.oldRentalCoupon.modifiers);
    } else {
      this.start = this.mission.start;
      this.end = this.mission.end;

      this.pricing_fuel = this.missionItem.pricing_fuel;
      this.pricing_driver = this.missionItem.pricing_driver;
    }

    this.source_worksite_name = this.setWorksiteTextForm(
      this.oldRentalCoupon.source_worksite_name,
      this.missionItem.source_location_name,
      this.missionItem.source_location_address,
      this.missionItem.source_location_city,
      this.missionItem.source_location_manual,
    );
    this.target_worksite_name = this.setWorksiteTextForm(
      this.oldRentalCoupon.target_worksite_name,
      this.missionItem.target_location_name,
      this.missionItem.target_location_address,
      this.missionItem.target_location_city,
      this.missionItem.target_location_manual,
    );

    await this.setSourceSupervisorsInformationForm();
    await this.setTargetSupervisorsInformationForm();
    this.setMachinesToolsToTransfer();
    this.setInformationsDateDependent();
    this.setInternalReferences();

    if (this.typeVue === 'create') {
      this.setSelectedModifiers(this.missionItem.modifiers);
    }
  },

  methods: {
    setMachinesToolsToTransfer() {
      if (this.typeVue !== 'create') {
        this.machines = this.oldRentalCoupon.machines;
        this.tools = this.oldRentalCoupon.tools;
        return;
      }

      if (this.missionItem.machines) {
        this.missionItem.machines.forEach(machine => {
          this.machines.push(machine.name);
        });
      }
      if (this.missionItem.machines_manual) {
        this.machines = this.machines.concat(this.missionItem.machines_manual);
      }

      if (this.missionItem.tools) {
        for (const tool in this.missionItem.tools) {
          this.tools.push(this.missionItem.tools[tool].name);
        }
      }

      if (this.missionItem.tools_manual) {
        this.tools = this.tools.concat(this.missionItem.tools_manual);
      }
    },
    async sendRentalCoupon() {
      if (
        this.missionItem.coupon_company &&
        !this.source_contact_email &&
        !this.target_contact_email &&
        !this.source_contact_phone &&
        !this.target_contact_phone &&
        this.status === 'send' &&
        (await this.sendWithNoEmail())
      ) {
        return;
      }

      if (this.typeVue !== 'create' && this.oldRentalCoupon.status !== 'save') {
        this.rentalCoupon = this.oldRentalCoupon;

        this.rentalCoupon.source_contact_email = this.source_contact_email;
        this.rentalCoupon.target_contact_email = this.target_contact_email;
        this.rentalCoupon.notes = this.notes;
      } else {
        const { userData } = await getInfo();

        this.rentalCoupon = {
          ride_id: this.mission.id,
          transfer_id: this.mission.id_transport,
          item_id: this.$route.params.idItem,
          driver_id: userData.agent_id,
          machine_id: this.mission.machine_id,

          status: this.status,
          start: this.start,
          end: this.end,

          machines: this.machines,
          tools: this.tools,

          source_builder_id: null,
          source_builder_name: null,
          source_contact_id: null,
          source_contact_name: null,
          source_contact_email: this.source_contact_email,
          source_worksite_id: null,
          source_worksite_name: null,

          target_builder_id: null,
          target_builder_name: null,
          target_contact_id: null,
          target_contact_name: null,
          target_contact_email: this.target_contact_email,
          target_worksite_id: null,
          target_worksite_name: null,

          prestation_times: this.completeTimes(this.times.prestation),
          bad_weather_times: this.completeTimes(this.times.badWeather),
          maintenance_times: this.completeTimes(this.times.maintenance),
          pricing_fuel: this.pricing_fuel,
          pricing_driver: this.pricing_driver,
          comments: this.comments,
          signature_contact: null,
          signature_driver: null,
          no_signature_contact: this.no_signature_contact,
          no_signature_source: this.no_signature_source,
          need_signature_source: this.missionItem.need_signature_source,
          coupon_company: this.missionItem.coupon_company,
          custom_coupon_company_id: this.missionItem.custom_coupon_company_id,
          custom_coupon_company_manual: this.missionItem.custom_coupon_company_manual,
          billing_company_id: this.missionItem.billing_company_id,

          notes: this.notes,
          worksite_reference: this.worksite_reference,
          order_number: this.order_number,

          pricing_information: this.mission.pricing_information,
          amount_flat_rates: this.mission.amount_flat_rates,
          added_modifiers: Object.values(this.selectedModifiers),
          custom_articles: this.missionItem.custom_articles,
          custom_modifiers: this.missionItem.custom_modifiers,
        };

        this.completeSourceBuilder();
        this.completeSourceContact();
        this.completeSourceWorksite();
        this.completeTargetBuilder();
        this.completeTargetContact();
        this.completeTargetWorksite();

        this.completeMachineCounteur();
        this.saveSignature('contact');
        this.saveSignature('driver');
        this.saveSignature('source');
      }

      if (this.typeVue !== 'create') {
        this.rentalCoupon['id'] = this.oldRentalCoupon.id;
      }

      const optionsVue = this.$refs.options;
      const internalInformationVue = this.$refs.internalInformation;
      this.rentalCoupon.attachmentsToDelete = [
        ...(optionsVue ? optionsVue.getAttachmentsToDelete() : []),
        ...internalInformationVue.getAttachmentsToDelete(),
      ].join('|');
      this.rentalCoupon.attachmentsToUpdate = {
        ...(optionsVue ? optionsVue.getAttachmentsToUpdate() : []),
        ...internalInformationVue.getAttachmentsToUpdate(),
      };

      const res = await this.send([
        ...(optionsVue ? optionsVue.getWaitingFiles() : []),
        ...internalInformationVue.getWaitingFiles(),
      ]);

      // if go back to "listeBonLocation" month will still be correct because the component is not unmounted so data is not reset
      let path = '/listeBonLocation';
      const query = {};

      // if not on "listeBonLocation", go back to associated transfer page
      if (!this.$router.options.history.state.back.includes(path)) {
        path = `/transfert/${this.rentalCoupon.transfer_id}/${this.$route.params.idRide}/${this.$route.params.idItem}`;
      } else {
        query.forceRefresh = true;
      }

      if (res && res.status && res.status === 'ok') {
        if (optionsVue) {
          optionsVue.resetFilesInformation();
        }
        internalInformationVue.resetFilesInformation();

        await this.$router.replace({ path, query });
      }
    },

    setModifiersKeys(modifiers) {
      modifiers.forEach((modifier) => {
        this.selectedModifiers[modifier.name] = modifier;
      });
    },
  },
};
</script>
