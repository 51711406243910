<template>
  <rental-delivery type-vue="update" type-mission="livraison" />
</template>

<script>
import RentalDelivery from '@/components/driver/rentalCoupon/RentalDelivery';

export default {
  name: 'RentalDeliveryUpdate',
  components: { RentalDelivery },
};
</script>
