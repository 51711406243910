import { getAllSupervisorOfBuilder } from '@/services/driver/rentalCoupon';

const mixinDataTransportRentalCoupon = {
  data() {
    return {
      missionItem: {},

      toggleCharge: true,
      toggleDischarge: true,
      segmentTargetContact: 'new', // ('available', 'new')
      targetSupervisorsBuilder: {},

      target_builder_name: '',
      target_contact_id: Number,
      target_contact_name: '',
      target_contact_email: '',
      target_contact_phone: '',
      target_worksite_name: '',
    };
  },
  methods: {
    /**
     * Get all supervisors of the source company if is necessary
     */
    async setSourceSupervisorsInformationForm() {
      if (this.missionItem.source_id) {
        this.sourceSupervisorsBuilder = await getAllSupervisorOfBuilder(
          this.missionItem.source_id,
        );

        if (this.sourceSupervisorsBuilder.length === 0) {
          this.sourceSupervisorsBuilder.push({
            name: this.missionDetail.source_contact,
            id: this.missionDetail.source_contact_id,
            email: this.missionDetail.source_contact_email,
          });
        }

        if (this.typeVue !== 'create') {
          this.source_contact_email = this.oldRentalCoupon.source_contact_email;

          if (this.oldRentalCoupon.source_contact_id) {
            this.segmentSourceContact = 'available';
            this.source_contact_id = this.oldRentalCoupon.source_contact_id;

            // if id we find the good supervisor to set the phone
            const filtered = this.sourceSupervisorsBuilder.filter((supervisor) => {
              return supervisor['id'] === this.oldRentalCoupon.source_contact_id;
            });

            if (filtered && filtered[0]) {
              this.source_contact_phone = filtered[0]['phone'];
            }

            return;
          }
          this.source_contact_name = this.oldRentalCoupon.source_contact_name;
          this.source_contact_id = this.sourceSupervisorsBuilder[0]['id'];
          return;
        }

        if (this.missionDetail.source_contact_id) {
          this.segmentSourceContact = 'available';
          this.source_contact_id = this.missionDetail.source_contact_id;
          if (this.missionItem.coupon_company === 'source') {
            this.source_contact_email = this.missionDetail.source_contact_email;
            this.source_contact_phone = this.missionDetail.source_contact_phone;
          }
          return;
        }
        this.source_contact_name = this.missionDetail.source_contact;
        this.source_contact_id = this.sourceSupervisorsBuilder[0]['id'];
        return;
      }

      this.source_contact_name = this.missionDetail.source_contact;
    },

    /**
     * Get all supervisors of the target company if is necessary
     */
    async setTargetSupervisorsInformationForm() {
      if (this.missionItem.target_id) {
        this.targetSupervisorsBuilder = await getAllSupervisorOfBuilder(
          this.missionItem.target_id,
        );

        if (this.targetSupervisorsBuilder.length === 0) {
          this.targetSupervisorsBuilder.push({
            name: this.missionDetail.target_contact,
            id: this.missionDetail.target_contact_id,
            email: this.missionDetail.target_contact_email,
          });
        }

        if (this.typeVue !== 'create') {
          this.target_contact_email = this.oldRentalCoupon.target_contact_email;

          if (this.oldRentalCoupon.target_contact_id) {
            this.segmentTargetContact = 'available';
            this.target_contact_id = this.oldRentalCoupon.target_contact_id;

            // if id we find the good supervisor to set the phone
            const filtered = this.targetSupervisorsBuilder.filter((supervisor) => {
              return supervisor['id'] === this.oldRentalCoupon.target_contact_id;
            });

            if (filtered && filtered[0]) {
              this.target_contact_phone = filtered[0]['phone'];
            }

            return;
          }
          this.target_contact_name = this.oldRentalCoupon.target_contact_name;
          this.target_contact_id = this.targetSupervisorsBuilder[0]['id'];
          return;
        }

        if (this.missionDetail.target_contact_id) {
          this.segmentTargetContact = 'available';
          this.target_contact_id = this.missionDetail.target_contact_id;
          if (this.missionItem.coupon_company === 'target') {
            this.target_contact_email = this.missionDetail.target_contact_email;
            this.target_contact_phone = this.missionDetail.target_contact_phone;
          }
          return;
        }
        this.target_contact_name = this.missionDetail.target_contact;
        this.target_contact_id = this.targetSupervisorsBuilder[0]['id'];
        return;
      }

      this.target_contact_name = this.missionDetail.target_contact;
    },

    completeSourceBuilder() {
      if (this.missionItem.source_id) {
        this.rentalCoupon.source_builder_id = this.missionItem.source_id;
        return;
      }
      this.rentalCoupon.source_builder_name = this.source_builder_name;
    },

    completeSourceContact() {
      if (this.segmentSourceContact === 'available') {
        this.rentalCoupon.source_contact_id = this.source_contact_id;
        return;
      }
      this.rentalCoupon.source_contact_name = this.source_contact_name;
    },

    /**
     * Called one time, just before sending data to API
     * Completes source worksite info
     *
     * Please keep in the same format as :
     * completeTargetWorksite inside dataTransportRentalCoupon
     * completeSourceWorksite inside RentalWorksite
     */
    completeSourceWorksite() {
      const defaultWorksiteName = this.setWorksiteTextForm(
        this.oldRentalCoupon.source_worksite_name,
        this.missionItem.source_location_name,
        this.missionItem.source_location_address,
        this.missionItem.source_location_city,
        null,
      );

      if (
        defaultWorksiteName === this.source_worksite_name &&
        this.missionItem.source_location_id
      ) {
        this.rentalCoupon.source_worksite_id = this.missionItem.source_location_id;
        return;
      }

      this.rentalCoupon.source_worksite_name = this.source_worksite_name;
    },

    completeTargetBuilder() {
      if (this.missionItem.target_id) {
        this.rentalCoupon.target_builder_id = this.missionItem.target_id;
        return;
      }
      this.rentalCoupon.target_builder_name = this.target_builder_name;
    },

    completeTargetContact() {
      if (this.segmentTargetContact === 'available') {
        this.rentalCoupon.target_contact_id = this.target_contact_id;
        return;
      }
      this.rentalCoupon.target_contact_name = this.target_contact_name;
    },

    /**
     * Called one time, just before sending data to API
     * Completes target worksite info
     *
     * Please keep in the same format as :
     * completeTargetWorksite inside dataTransportRentalCoupon and RentalWorksite
     */
    completeTargetWorksite() {
      const defaultWorksiteName = this.setWorksiteTextForm(
        this.oldRentalCoupon.target_worksite_name,
        this.missionItem.target_location_name,
        this.missionItem.target_location_address,
        this.missionItem.target_location_city,
        null,
      );

      if (
        defaultWorksiteName === this.target_worksite_name &&
        this.missionItem.target_location_id
      ) {
        this.rentalCoupon.target_worksite_id = this.missionItem.target_location_id;
        return;
      }

      this.rentalCoupon.target_worksite_name = this.target_worksite_name;
    },
  },
};

export { mixinDataTransportRentalCoupon };
