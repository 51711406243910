<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-img
        class="margin-logo-locatiers"
        src="/assets/logo/LOGO-CMJN.png"
        alt="Logo Locatiers"
      />
      <form @submit.prevent="handleLogin()">
        <ion-item mode="md">
          <ion-icon slot="start" color="dark" :icon="callOutline"></ion-icon>
          <ion-label position="floating">Numéro de téléphone</ion-label>
          <ion-input
            name="tel"
            inputmode="tel"
            type="tel"
            autocomplete="tel"
            enterkeyhint="next"
            v-model="userInfo.email"
          ></ion-input>
        </ion-item>
        <span class="span-danger" v-if="errors.email">{{
          errors.email[0]
        }}</span>
        <ion-item mode="md">
          <ion-icon
            slot="start"
            color="dark"
            :icon="lockClosedOutline"
          ></ion-icon>
          <ion-label position="floating">Mot de passe</ion-label>
          <ion-input
            name="password"
            v-model="userInfo.password"
            type="password"
            enterkeyhint="send"
          ></ion-input>
        </ion-item>
        <span class="span-danger" v-if="errors.password">{{
          errors.password[0]
        }}</span>
        <ion-button
          color="tertiary"
          class="mt-20"
          strong="true"
          expand="block"
          type="submit"
          >Connexion</ion-button
        >
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonInput,
  IonLabel,
  IonButton,
  IonItem,
  IonImg,
  IonPage,
  IonIcon,
} from '@ionic/vue';
import { callOutline, lockClosedOutline } from 'ionicons/icons';

import { fetchToken, getInfo, setupIntercom } from '@/services/users';

export default {
  name: 'Connexion',
  components: {
    IonContent,
    IonInput,
    IonLabel,
    IonButton,
    IonItem,
    IonImg,
    IonPage,
    IonIcon,
  },
  data() {
    return {
      userInfo: {
        email: '',
        password: '',
      },
      errors: {},
    };
  },
  setup() {
    return {
      callOutline,
      lockClosedOutline,
    };
  },
  mounted() {
    // When user is here, always reset Matomo ID
    const _paq = (window._paq = window._paq || []);
    _paq.push(['resetUserId']);
    _paq.push(['appendToTrackingUrl', '']);
  },
  methods: {
    /**
     * Allows you to see if the data entered by the user is correct
     */
    async handleLogin() {
      const { errors } = await fetchToken(this.userInfo);
      this.errors = errors;

      if (this.errors.email || this.errors.password) {
        return;
      }

      const { userData } = await getInfo();
      if (userData) {
        setupIntercom({ userData });

        if (userData.agent.kind === 'driver') {
          this.$router.push('/planning');
        } else if (userData.company.kind === 'renter') {
          this.$router.push('/el/planning');
        } else if (userData.company.kind === 'builder') {
          console.log(
            'Partie de la plateforme pour cet utilisateur pas encore terminée',
          );
        }
        return;
      }
    },
  },
};
</script>

<style scoped>
.margin-logo-locatiers {
  margin: 50px 10px;
}

.margin-mdp-oublie {
  margin: 5px 0 20px 10px;
}
</style>
