<template>
  <rental-transfer type-vue="update" type-mission="transfert" />
</template>

<script>
import RentalTransfer from '@/components/driver/rentalCoupon/RentalTransfer';
export default {
  name: 'RentalTransferUpdate',
  components: { RentalTransfer },
};
</script>
