<template>
  <ion-content class="ion-padding">
    <form @submit.prevent="closePopover(textNewMachine)">
      <ion-label class="bold" position="floating">
        Nom de la machine :</ion-label
      >
      <ion-input
        placeholder="Saisir ..."
        v-model="textNewMachine"
        required="true"
      ></ion-input>
      <ion-button class="align-center" color="success" type="submit"
        >Valider</ion-button
      >
    </form>
  </ion-content>
</template>

<script>
import { IonContent, IonInput, IonButton, IonLabel } from '@ionic/vue';

export default {
  name: 'PopoverAddMachine',
  components: {
    IonContent,
    IonInput,
    IonButton,
    IonLabel,
  },
  props: ['closePopover'],

  data() {
    return {
      textNewMachine: '',
    };
  },
};
</script>
