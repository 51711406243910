<template>
  <ion-page id="mission">
    <WhiteHeader
      :back-button="true"
      route-back="/planning"
      title="Détails d'une mission"
    />
    <ion-content class="ion-padding">
      <ion-refresher
        id="refresher-detail"
        slot="fixed"
        @ionRefresh="doRefresh($event)"
        @click="doRefresh($event)"
        ><ion-refresher-content></ion-refresher-content
      ></ion-refresher>

      <DayPart :part="mission.day_part"></DayPart>
      <Date v-if="humanStart" :start="humanStart" :end="humanEnd" />
      <hr class="color-hr" />

      <Equipment
        :machine_name="mission.machine_name"
        :machine_category="mission.machine_category"
        :machine_number="mission.machine_number"
        :machine_translation="mission.machine_translation"
        :machine_poids="mission.machine_poids"
        :tools="mission.tools"
        :attachments="mission.machine_attachments"
      />

      <hr class="color-hr" />

      <information
        :name-company="mission.builder"
        :manager="missionDetail.manager"
        :manager_phone="missionDetail.manager_phone"
        :manager2="missionDetail.manager2"
        :manager2_phone="missionDetail.manager2_phone"
        :worksite_city="mission.worksite_city"
        :worksite_name="mission.worksite_name"
        :worksite_address="mission.worksite_address"
        :worksite_manual="mission.worksite_manual"
        :worksite_coordinates="mission.worksite_coordinates"
        :instructions="mission.instructions"
        :order_number="mission.order_number"
        :worksite_reference="mission.worksite_reference"
        :isReservation="true"
        :attachments="mission.attachments"
        ref="worksiteMap"
      />

      <hr class="color-hr" />

      <liste-rental-coupon
        :rental-coupons="missionDetail.rental_coupons"
        type-mission="reservation"
      />
      <new-rental-coupon
        v-if="mission.pricing_information"
        type-mission="reservation"
        :has-questions="mission.has_questions"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  toastController,
} from '@ionic/vue';
import {
  forceRefreshDetailMission,
  forceRefreshPlanning,
  getDetailMission,
  getMissionOrder,
} from '@/services/driver/missionsOrders';
import { convertDateToReadableDate } from '@/services/utils/dateUtils';
import Equipment from '@/components/driver/detailMission/Equipment';
import Information from '@/components/driver/detailMission/Information';
import Date from '@/components/driver/detailMission/Date';
import DayPart from '@/components/driver/card/detailCardPlanning/DayPart.vue';
import NewRentalCoupon from '@/components/driver/detailMission/NewRentalCoupon';
import ListeRentalCoupon from '@/components/driver/detailMission/ListeRentalCoupon';
import WhiteHeader from '@/components/driver/header/WhiteHeader';

export default {
  name: 'WorksiteDetail',
  components: {
    WhiteHeader,
    ListeRentalCoupon,
    NewRentalCoupon,
    Date,
    DayPart,
    Information,
    Equipment,
    IonPage,
    IonContent,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      mission: {},
      missionDetail: {},
      refreshInterval: null,
    };
  },

  // Launch auto-refresh when page gets displayed
  ionViewDidEnter() {
    this.doRefresh();

    this.refreshInterval = setInterval(() => {
      this.doRefresh();
    }, 60 * 1000);

    setTimeout(() => {
      this.$refs.worksiteMap.initMap();
    }, 0);
  },

  ionViewWillLeave() {
    this.$refs.worksiteMap.removeMap();
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  mounted() {
    Promise.all([
      getMissionOrder(this.$route.params.id, this.$route.params.idRide, 'reservation'),
      getDetailMission('reservation', this.$route.params.id, null),
    ]).then(([mission, missionDetail]) => {
      if (!mission) {
        this.backPlanning();
        return;
      }

      this.mission = mission;
      this.missionDetail = missionDetail;

      // Init map just in case
      this.$nextTick(() => {
        this.$refs.worksiteMap.initMap();
      });
    });
  },

  methods: {
    doRefresh(event) {
      forceRefreshPlanning();

      if (!this.$route || !this.$route.params || !this.$route.params.id) return;

      Promise.all([
        getMissionOrder(this.$route.params.id, this.$route.params.idRide, 'reservation'),
        forceRefreshDetailMission('reservation', this.$route.params.id, null),
      ])
        .then(([mission, missionDetail]) => {
          if (!mission) {
            this.backPlanning();
            return;
          }

          this.mission = mission;
          this.missionDetail = missionDetail;
        })
        .catch(() => {
          console.log('Unable to force-refresh worksite detail.');
        })
        .finally(() => {
          if (event && event.target && event.target.complete) {
            event.target.complete();
          }
        });
    },

    async backPlanning() {
      const toast = await toastController.create({
        header: 'Changement',
        message: 'La mission ne vous est plus affectée',
        duration: 3000,
        color: 'success',
        position: 'top',
        cssClass: 'toast-rental-coupon',
      });
      await toast.present();

      this.$router.push('/planning');
    },
  },

  computed: {
    humanStart() {
      if (this.mission && this.mission.start) {
        return convertDateToReadableDate(this.mission.start);
      }
      return '';
    },

    humanEnd() {
      if (this.mission && this.mission.end) {
        return convertDateToReadableDate(this.mission.end);
      }
      return '';
    },
  },
};
</script>
