<template>
  <ion-page>
    <ion-content class="ion-text-center ion-padding">
      <div class="align-data">
        <ion-title>Erreur 404</ion-title>
        <ion-label
          >La page que vous chercher n'est pas disponible ou n'existe
          pas</ion-label
        >
        <ion-button v-if="user" color="primary" @click="goToHomepage"
          >Page d'accueil<ion-icon
            slot="end"
            :icon="arrowForwardOutline"
          ></ion-icon>
        </ion-button>
        <ion-button v-else color="primary" @click="goToConnexionPage"
          >Page de connexion<ion-icon
            slot="end"
            :icon="arrowForwardOutline"
          ></ion-icon>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonTitle,
  IonLabel,
  IonContent,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { clearData, getInfo } from '@/services/users';
export default {
  name: 'NotFound',
  components: {
    IonPage,
    IonLabel,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
  },
  setup() {
    return {
      arrowForwardOutline,
    };
  },
  data() {
    return {
      user: null,
    };
  },
  async mounted() {
    this.user = await getInfo();
  },
  methods: {
    goToConnexionPage() {
      clearData();
      this.$router.push('/connexion');
    },
    goToHomepage() {
      if (this.user) {
        if (this.user.userData.agent.kind === 'driver') {
          this.$router.push('/planning');
        } else if (this.user.userData.company.kind === 'renter') {
          this.$router.push('/el/planning');
        } else if (this.user.userData.company.kind === 'builder') {
          console.log(
            'Partie de la plateforme pour cet utilisateur pas encore terminée',
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.align-data {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
